import updateOrganizations from "./updateOrganizations";
import updateLanguage from "./updateLanguage";

const reducer = (state, action) => {
    return {
        data: updateOrganizations(state, action),
        language: updateLanguage(state, action)
    };
};

export default reducer;
