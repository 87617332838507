import React, {useEffect} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import APIService from "../../services/api-service";

import "./main.css";
import "./controls.css";
import "./shortcuts.css";
import "./sliding-pane.css"

import Map from "../map/map";
import ControlsHeader from "../controls-header/controls-header";
import SearchInput from "../controls/search-input";
import About from "../about";
import {getWording, switchLanguage} from "../../actions/actions-language";
import {connect} from "react-redux";
import Hide from "../hide";

const languageMain = process.env.REACT_APP_LANGUAGE_MAIN;
const apiService = new APIService()

const App = props =>  {

    const { getWording, switchLanguage, wording } = props;
    const { pathname } = useLocation();

    useEffect(() => {
        switchLanguage(localStorage.getItem('mapka_language') || languageMain)
        if (!wording || !wording.heading) getWording();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const hideOrganization = async (e, t) => {
            const result = await apiService.hideOrganizationByEmail(e, t)
            if (result) {
                window.alert('Organization hidden! You need to wait for 5m for sync.')
            }
        }

        const query = new URLSearchParams(window.location.search);
        const email = query.get('email')
        const token = query.get('token')

        if (email && token) {
            hideOrganization(email, token)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!wording || !wording.heading) return null;

    return (

        <div className="wrapper">
            <div className="container">
                <Switch>
                    <Route path='/' exact>
                        <Map />
                    </Route>
                    <Route path='/about' exact>
                        <About />
                    </Route>
                    <Route path='/hide' exact>
                        <Hide />
                    </Route>
                </Switch>
                <ControlsHeader pathname={pathname} />
                {pathname === '/' &&
                    <div className='input-search-mobile-container d-mobile'>
                        <SearchInput noBorder/>
                    </div>
                }

            </div>
        </div>

    );
}

const mapStateToProps = ({ language: { language, wording }}) => {
    return { language, wording };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWording: getWording(dispatch),
        switchLanguage: switchLanguage(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
