
const SERVER_URL = process.env.REACT_APP_API_URL
const CONNECTION_ERROR_MESSAGE = 'Произошла ошибка при запросе данных';

export const getAsyncFetch = async (urlPath, method='GET', params=null) => {
    try {
        const url = SERVER_URL + urlPath;
        const request = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            ...(params && {body: JSON.stringify(params)})
        }

        const response = await fetch(url, request);
        if (!response.ok) {
            console.log(`An error has occured: ${response.status}`)
            return Promise.reject(CONNECTION_ERROR_MESSAGE)
        }
        const json = await response.json();
        if (!json.result) {
            console.log(`An error during json parse`)
            return Promise.reject(CONNECTION_ERROR_MESSAGE)
        }
        return json.result
    } catch (err) {
        console.log(err)
    }
}
