import React from "react";
import SearchInput from "../controls/search-input";
import logo from '../../assets/logo.png'
import {Link} from "react-router-dom";
import { switchLanguage } from "../../actions/actions-language";
import {connect} from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const formMain = process.env.REACT_APP_FORM_MAIN
const formAlt = process.env.REACT_APP_FORM_ALT;
const formEn = process.env.REACT_APP_FORM_EN;
const languageMain = process.env.REACT_APP_LANGUAGE_MAIN;
const languageAlt = process.env.REACT_APP_LANGUAGE_ALT;
const languageEn = process.env.REACT_APP_LANGUAGE_EN;

const formToOpen = {
    ru: formAlt,
    by: formMain,
    ua: formMain,
    en: formEn
}

const ControlsHeader = props => {

    const { pathname, language, wording, switchLanguage } = props;
    const { width } = useWindowDimensions();

    return (
        <div>
            <div className='controlsHeaderContainer shadow'>
                <Link to='/'>
                    <div className='mr-20'>
                        {languageMain === 'by' && <img src={logo} width={50} alt='logo' />}
                        {languageMain === 'ua' && <p className='strong'><span style={{color: '#2558b3'}}>mapa</span><span style={{color: '#f8d849'}}>help</span></p>}
                    </div>
                </Link>
                {pathname === '/' &&
                    <div className='mr-20 d-flex-1 d-web'>
                        <SearchInput />
                    </div>
                }
                <div className='d-flex-ac'>
                    {pathname === '/' &&
                        <div className='d-flex mr-15'>
                            <a href={formToOpen[language]} target='_blank' rel='noopener noreferrer'><p
                                className='button-link mr-15'>{width > 400 ? wording['menu1'][language] : wording['menu1'][language].split(' ')[0]}</p></a>
                            <Link to='about'><p className='button-link'>{wording['menu2'][language]}</p></Link>
                        </div>
                    }
                    {pathname !== '/' &&
                        <div className='mr-15 d-flex'>
                            <Link to='/'><p className='button-link'>{wording['menu3'][language]}</p></Link>
                        </div>
                    }
                    <div className='w-50'>
                        <select className='select-search w-55' value={language} onChange={(e) => switchLanguage(e.target.value)}>
                            <option value={languageMain}>{languageMain.toUpperCase()}</option>
                            <option value={languageAlt}>{languageAlt.toUpperCase()}</option>
                            {languageEn && <option value={languageEn}>{languageEn.toUpperCase()}</option>}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = ({ language: { language, wording }}) => {
    return { language, wording };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchLanguage: switchLanguage(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlsHeader);

