import React from "react";
import Linkify from 'react-linkify';
import {classifySocialNetwork} from "../../utils/social";
import {AiFillFacebook, AiFillInstagram, AiFillTwitterCircle, AiOutlineLink} from "react-icons/ai";
import {FACEBOOK, INSTAGRAM, TWITTER, LINK} from "../../utils/social";
import ReactPlayer from "react-player";
import {connect} from "react-redux";

import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ICO_SIZE = 20;

const getIco = (type) => {
    switch (type) {
        case FACEBOOK: return <AiFillFacebook className='fc-url' size={ICO_SIZE} />;
        case INSTAGRAM: return <AiFillInstagram className='fc-url' size={ICO_SIZE} />;
        case TWITTER: return <AiFillTwitterCircle className='fc-url' size={ICO_SIZE} />;
        case LINK: return <AiOutlineLink className='fc-url' size={ICO_SIZE} />;
        default: return <AiOutlineLink className='fc-url' size={ICO_SIZE} />;
    }
}

const Organization = props => {

    const { item: {Name, Category, CategoryBy, CategoryEn, CategoryRu, Image, VideoUrl, Services, Address, Phone, Email, WorkTime, SocialNetworks, Website}, language, wording } = props;

    const socialNetworksBlock = [];
    if (SocialNetworks) {
        const snString = SocialNetworks.split('\n\n').join('\n');
        const snArray = snString.split('\n');
        snArray.forEach(item => {
             if (item.trim()) {
                 socialNetworksBlock.push(classifySocialNetwork(item.trim()))
             }
        })
    }

    const categoryName = {
        en: CategoryEn,
        ru: CategoryRu,
        ua: Category,
        by: CategoryBy

    }

    return (
        <div>
            <Linkify>
            <p className='fs-10 mb-5'>{categoryName[language]}</p>
            <h3>{Name}</h3>
            <div className='separator mt-10' />
            {Image &&
                <div className='mt-20 d-flex'>
                    {Image.length > 1 &&
                       <Carousel showThumbs={false} showIndicators={false}>
                           {Image.map((item, index) => <div key={index}>
                               <img src={item.url} width='100%' height={200} style={{objectFit: 'cover'}} alt='organization'/>
                           </div>)}
                       </Carousel>}
                    {Image.length === 1 &&
                        <img src={Image[0].url} width='100%' alt='organization'/>
                    }
                </div>}
            {VideoUrl && <div className='mt-20'><ReactPlayer width="100%"
                                      controls={true} playing={false} url={VideoUrl}/></div>}
            {Services &&
            <div className='mt-20'>
                <p className='strong'>{wording['card_services'][language]}</p>
                <p className='mt-5 pre-line'>{Services}</p>
            </div>}
            {Website &&
                <div className='mt-20'>
                    <p className='pre-line'>{Website}</p>
                </div>
            }
            {WorkTime &&
            <div className='mt-20'>
                <p className='strong'>{wording['card_time'][language]}</p>
                <p className='mt-5 pre-line'>{WorkTime}</p>
            </div>}
            {Address &&
            <div className='mt-20'>
                <p className='strong '>{wording['card_address'][language]}</p>
                <p className='mt-5 pre-line'>{Address}</p>
            </div>}
            {(Phone || Email) &&
            <div className='mt-20'>
                <p className='strong '>{wording['card_contacts'][language]}</p>
                <p className='mt-5 pre-line'>{Phone}</p>
                <p className='mt-5 pre-line'>{Email}</p>
            </div>}
            {SocialNetworks && socialNetworksBlock.length &&
            <div className='mt-20'>
                <p className='strong '>{wording['card_social'][language]}</p>
                <div className='mt-10 pre-line'>
                    {socialNetworksBlock.map((item, index) => {
                        const {name, url, ico} = item
                        return <div key={index} className='d-flex-ac mb-10'>
                            <div className='mt-5 mr-5'>{getIco(ico)}</div>
                            <a href={url} target='_blank' rel='noopener noreferrer' className='mr-5'>{name}</a>
                        </div>
                    })}
                </div>
            </div>}
            </Linkify>
        </div>
    );
}

const mapStateToProps = ({ language: { language, wording }}) => {
    return { language, wording };
};

export default connect(mapStateToProps)(Organization);

