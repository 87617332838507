import {ORGANIZATIONS_LOADED, ORGANIZATIONS_ERROR, ORGANIZATIONS_FILTER, CATEGORIES_UPDATE} from "../constants/actions";

const updateOrganizations = (state, action) => {

    if (state === undefined) {
        return {
            organizations: [],
            filtered: [],
            categories: [],
            countries: [],
            currentCountry: {value: 'Беларусь', lat: 53.9006011, lng: 27.558972},
            organizationsError: null,
            categoriesError: null,
        };
    }

    switch (action.type) {

        case ORGANIZATIONS_LOADED: {

            const countriesArray = [];
            const categoriesArray = [];
            const organizationsWithCoordinates = []
            action.payload.forEach(organization => {

                organizationsWithCoordinates.push({
                    ...organization, lat: Number(organization.Lat), lng: Number(organization.Lng)
                })

                const countryExistedItem = countriesArray.find(item => item.value === organization.Country);
                if (!countryExistedItem && organization.Country) {
                    const lats = [];
                    const lngs = [];
                    action.payload.forEach(item => {
                        if (item.Country === organization.Country && item.Lat && item.Lng) {
                            lats.push(Number(item.Lat))
                            lngs.push(Number(item.Lng))
                        }
                    })
                    const averageLat = lats.reduce((a,b) => a + b, 0) / lats.length;
                    const averageLng = lngs.reduce((a,b) => a + b, 0) / lngs.length;
                    countriesArray.push({
                        value: organization.Country,
                        ru: organization.Country,
                        by: organization.CountryBy,
                        ua: organization.Country,
                        en: organization.Country,
                        lat: averageLat,
                        lng: averageLng
                    })
                }

                const categoryExistedItem = categoriesArray.find(item => item.value === organization.Category );
                if (!categoryExistedItem && organization.Category) {
                    categoriesArray.push({
                        value: organization.Category,
                        en: organization.CategoryEn,
                        by: organization.CategoryBy,
                        ua: organization.Category,
                        ru: organization.CategoryRu,
                        icon: organization.icon,
                        selected: false,
                        num: 1
                    })
                } else {
                    categoryExistedItem.num++;
                }

            })

            countriesArray.sort(function (a, b) {
                return a.value.localeCompare(b.value);
            });

            const countriesFinalArray = [{value: '', by: 'Усе краіны', ru: 'Все страны', ua: 'Усі країни', en: 'All Countries'}, ...countriesArray]

            return {
                ...state.data,
                organizations: organizationsWithCoordinates,
                countries: countriesFinalArray,
                categories: categoriesArray,
                organizationsError: null
            }
        }

        case ORGANIZATIONS_ERROR:
            return {
                ...state.data,
                organizations: null,
                organizationsError: action.payload
            };

        case ORGANIZATIONS_FILTER: {


            const { country, search, categoriesFilter } = action.payload;
            const { organizations } = state.data;

            const newCountry = country !== state.data.currentCountry ? state.data.countries.find(el => el.value === country) : null

            const selectedCategories = categoriesFilter.filter(el => el.selected).map(el => el.value)
            const organizationsByCategory = selectedCategories.length ? organizations.filter(el => selectedCategories.includes(el.Category)) : [...organizations]

            const filterOrganizations = (search && search.length > 2)
                ? organizationsByCategory.filter(item => {
                    const text = String(item.Name + item.Category + item.Services).toLowerCase();
                    console.log(item)
                    if (country) {
                        return (item.Country === country && text.indexOf(search.toLowerCase()) > -1)
                    } else {
                        return (text.indexOf(search.toLowerCase()) > -1)
                    }
                })
                : organizationsByCategory;


            return {
                ...state.data,
                filtered: filterOrganizations,
                ...(newCountry && {currentCountry: newCountry})
            }
        }

        case CATEGORIES_UPDATE:
            return {
                ...state.data,
                categories: action.payload,
            };

        default:
            return state.data;
    }
};

export default updateOrganizations;
