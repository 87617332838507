import APIService from "../services/api-service";
import { LANGUAGE_LOADED, LANGUAGE_SWITCHED } from "../constants/actions";
import { createAction } from "../utils/actions-factory";

const apiService = new APIService();

export const getWording = (dispatch) => () => {
    apiService.getWording()
        .then((data) => dispatch(createAction(LANGUAGE_LOADED, data)))
        .catch((err) => console.log(err));
}

export const switchLanguage = (dispatch) => (language) => {
    localStorage.setItem('mapka_language', language)
    dispatch(createAction(LANGUAGE_SWITCHED, language))
}
