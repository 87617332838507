import React, {useState} from "react";
import {connect} from "react-redux";
import APIService from "../../services/api-service";
const apiService = new APIService()

const formMain = process.env.REACT_APP_FORM_MAIN
const formAlt = process.env.REACT_APP_FORM_ALT;
const formEn = process.env.REACT_APP_FORM_ALT;

const formToOpen = {
    ru: formAlt,
    by: formMain,
    ua: formMain,
    en: formEn
}

function isEmail(text) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
}

const About = props => {

    const { language, wording } = props;

    const [email, setEmail] = useState();

    const sendEmailHandler = async () => {
        if (!isEmail(email)) {
            window.alert(wording['email_error'][language]);
            return;
        }
        const result = await apiService.getLinkByEmail(email);
        if (result) {
            window.alert(wording['email_confirm'][language]);
        }
    }

    return (
        <div className='about-container mt-75'>
            <h2>{wording['heading'][language]}</h2>
            <div className='mt-30'>
                <p className='strong'>{wording['subheading1'][language]}</p>
                <p>{wording['text1'][language]}</p>
            </div>
            <div className='mt-30'>
                <p className='strong'>{wording['subheading2'][language]}</p>
                <p>{wording['text2'][language]}</p>
            </div>
            <div className='mt-30'>
                <p className='strong'>{wording['subheading3'][language]}</p>
                <p>{wording['text3'][language]}</p>
                <div className='mt-20'>
                    <a href={formToOpen[language]} target='_blank' rel='noopener noreferrer'><p
                        className='button-link mr-30'>{wording['CTA'][language]}</p></a>
                </div>
            </div>
            <div className='separator mt-50 mb-20' />
            <div>
                <p>{wording['email_restore'][language]}</p>
                <div className='mt-20 d-flex-wrap-ac' style={{gap: 10}}>
                    <input className='w-200' value={email} onChange={e => setEmail(e.target.value)} />
                    <button className='button-clean' onClick={sendEmailHandler}>{wording['email_button'][language]}</button>
                </div>
            </div>
            <div className='mt-30'>
                <p className='strong'>{wording['contact'][language]}</p>

                <div className='mt-20'>
                    <a href='mailto:info@bymapka.me' target='_blank' rel='noopener noreferrer'><p
                        className='button-link mr-30'>info@bymapka.me</p></a>
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = ({ language: { language, wording }}) => {
    return { language, wording };
};

export default connect(mapStateToProps)(About);

