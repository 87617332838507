import React from "react";
import {connect} from "react-redux";
import SearchPreviewCard from "./search-preview-card";

const SearchPreview = props => {

    const { filtered, onClick, top } = props;

    if (!filtered.length || filtered.length > 20) return null;

    return (
        <div>
            <div className='search-preview-container' style={{top: top}}>
                <div className='search-preview-content'>
                    {filtered.map((item, index) => <SearchPreviewCard key={index} item={item} onClick={onClick} />)}
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = ({ data: { filtered }}) => {
    return { filtered };
};


export default connect(mapStateToProps)(SearchPreview);

