import { getAsyncFetch } from "../../utils/fetcher";

const ORGANIZATIONS = 'organizations';
const CATEGORIES = 'categories';
const WORDING = 'wording';

export default class APIService {

    getOrganizations = async () => {
      return await getAsyncFetch(`${ORGANIZATIONS}`);
    }

    getCategories = async () => {
        return await getAsyncFetch(`${CATEGORIES}`);
    }

    getWording = async () => {
        return await getAsyncFetch(`${WORDING}`);
    }

    getLinkByEmail = async (email) => {
        return await getAsyncFetch(`${ORGANIZATIONS}/hide?email=${email}`);
    }

    hideOrganizationByEmail = async (email, token) => {
        return await getAsyncFetch(`${ORGANIZATIONS}/hide/confirm?email=${email}&token=${token}`);
    }
}
